<template>
  <div
    v-if="coworkerWithFallback"
    class="consulting relative overflow-hidden h-64 rounded-sm font-semibold uppercase"
    :class="[
      { 'consulting-small p-5 mb-6 lg:mb-0': small },
      { 'consulting-large p-10 my-6 hidden lg:block': !small },
    ]"
  >
    <img
      v-if="small"
      class="absolute"
      alt="media-line employee"
      :src="coworkerWithFallback.image"
    />
    <div class="relative">
      <h3 class="mb-3 font-semibold text-xl text-grey-darkest">
        {{ coworkerWithFallback.text }}
      </h3>
      <div class="text-base">
        <a class="transition" :href="'tel:' + coworkerWithFallback.phone">
          {{ coworkerWithFallback.phone }}
        </a>
        <a class="transition" :href="'mailto:' + coworkerWithFallback.email">
          {{ coworkerWithFallback.email }}
        </a>
      </div>
    </div>
    <BookAppointmentButton class="text-xs mt-4 relative" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BookAppointmentButton from '~/components/menu/BookAppointmentButton'

export default {
  components: {
    BookAppointmentButton,
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    coworker: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('settings', ['consulter']),

    coworkerWithFallback() {
      if (!this.coworker) {
        return this.consulter
      }
      return this.coworker
    },
  },
}
</script>

<style lang="scss" scoped>
.consulting {
  background-repeat: no-repeat;
  background-size: cover;
}

.consulting-small {
  background-image: url('../assets/images/ill/bg-wave.svg');
  background-position: top center;
}

.consulting-large {
  background-image: url('../assets/images/ill/advice.svg');
  background-position: bottom right;
}

a {
  @apply block py-1 text-white;

  &:hover {
    @apply text-red;
  }
}

img {
  height: 280px;
  right: -120px;
  bottom: -50px;
}
</style>
