import Vue from 'vue'

import AppLink from '~/components/AppLink'
import ComponentsLoader from '~/components/ComponentsLoader'
import ImgSvg from '~/components/ImgSvg'

// components
import TextContent from '~/components/TextContent'
import References from '~/components/References'
import Consulting from '~/components/Consulting'
import Youtube from '~/components/Youtube'
import ImageSlider from '~/components/ImageSlider'
import TimifyWidget from '~/components/TimifyWidget'

Vue.component('TextContent', TextContent)
Vue.component('TimifyWidget', TimifyWidget)
Vue.component('References', References)
Vue.component('Consulting', Consulting)
Vue.component('Youtube', Youtube)
Vue.component('ImageSlider', ImageSlider)

Vue.component('AppLink', AppLink)
Vue.component('ComponentsLoader', ComponentsLoader)
Vue.component('ImgSvg', ImgSvg)
