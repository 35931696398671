import set from 'lodash/set'

export const state = () => ({
  availableMethods: ['creditcard'],
  address: {
    toc: false,
    useDeliveryAddress: true,
    delivery: {
      type: 'Lieferadresse',
      firstname: '',
      lastname: '',
      company: '',
      street: '',
      nr: '',
      city: '',
      zip: '',
    },
    invoice: {
      type: 'Rechnungsadresse',
      firstname: '',
      lastname: '',
      company: '',
      street: '',
      nr: '',
      city: '',
      zip: '',
    },
    tel: '',
    email: '',
  },

  paymentMethods: {
    name: null,
    card: {
      owner: '',
      number: '',
      date: '',
      cvc: '',
    },
  },

  updated: null,
})

export const actions = {}

export const mutations = {
  updateField(state, { field, value }) {
    set(state, field, value)
    set(state, 'updated', Date.now())
  },
}
