<template>
  <component :is="linkProps.element" v-bind="linkProps">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    openInNewTab: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'a',
    },
  },
  computed: {
    linkProps() {
      if (this.to.match(/^(http(s)?|ftp):\/\//)) {
        return {
          element: 'a',
          href: this.to,
          target: this.openInNewTab ? '_blank' : '_self',
          rel: 'noopener',
        }
      }
      return {
        element: 'nuxt-link',
        to: `${this.to}`,
        tag: this.tag,
      }
    },
  },
}
</script>
