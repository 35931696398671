/**
 * Round to equal or next lower 5 cents
 *
 * @param Number value in Franken
 * @returns Number
 */
export function roundFloorCHF(value) {
  value = typeof value === 'string' ? Number.parseFloat(value) : value

  // round to cents for cases like 0.99999999999 => 1
  let cents = Math.round(value * 100)

  // round next lower 5 cent
  cents = Math.floor(cents / 5) * 5

  return cents / 100
}

// TESTS
/*
;(function() {
  const cases = [
    { value: '15', expected: '15.00' },
    { value: 25, expected: '25.00' },
    { value: '35.08', expected: '35.05' },
    { value: 42.04, expected: '42.00' },
    { value: 55.55, expected: '55.55' },
    { value: 65.08, expected: '65.05' },
    { value: 99.9999999999999, expected: '100.00' },
    { value: 111.111111111111, expected: '111.10' },
  ]

  const success = cases.reduce(function(carry, test) {
    const rounded = roundFloorCHF(test.value)
    const fixed = rounded.toFixed(2)
    const match = fixed === test.expected
    console.log({
      value: test.value,
      reult: rounded,
      expected: test.expected,
      fixed,
      match,
    })

    return carry && match
  })

  console.log(`test ${success ? 'succeeded' : 'failed'}`)
})()
*/
