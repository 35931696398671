<template>
  <div>
    <!-- NEWSLETTER SUBSCRIPTION -->

    <Newsletter class="wow fadeIn" />

    <!-- CONTACT AND MAP -->

    <Address class="wow fadeIn pt-3 md:pt-14 pb-0 lg:pb-14" />

    <!-- FOOTER -->

    <footer class="relative bg-blue-light">
      <Container>
        <Footer class="-mx-4" />
      </Container>
    </footer>
  </div>
</template>

<script>
import Newsletter from '~/components/Newsletter'
import Footer from '~/components/sections/Footer'
import Address from '~/components/Address'

import Container from '~/components/layoutelements/Container'

export default {
  components: {
    Newsletter,
    Footer,
    Address,

    Container,
  },
}
</script>
