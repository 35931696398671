export default function ({ $axios, $auth, ssrContext }, inject) {
  if (!inject || ssrContext) {
    return
  }

  // Create a custom axios instance
  const shop = $axios.create()

  // Set baseURL to something different
  shop.setBaseURL(process.env.NUXT_ENV_API_SHOP)

  // Inject to context as $shop
  inject('shop', shop)
}
