<template>
  <label
    class="label relative block pt-1 pl-9 mb-3 font-sans font-semibold text-xs text-white uppercase cursor-pointer"
    @click="$emit('checked')"
  >
    <slot />
    <input
      type="checkbox"
      :checked="value ? 'checked' : false"
      v-bind="$attrs"
      @change="changed"
    />
    <span class="checkmark" />
  </label>
</template>

<script>
export default {
  $_veeValidate: {
    value() {
      return this.value
    },
    name() {
      return this.$attrs.name
    },
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changed($event) {
      this.$emit('input', $event.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
/* The label */
.label {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 23px;
}

.label input {
  left: 11px;
  top: 23px;
  @apply absolute opacity-0 cursor-pointer h-px w-px;
}

.checkmark {
  @apply absolute pin rounded-sm;

  height: 23px;
  width: 23px;
}

.light .checkmark {
  border: 2px solid white;
}

.dark .checkmark {
  border: 2px solid #3c5174;
}

.label input ~ .checkmark {
  transition: all 0.5s ease;
}

.label.light:hover input ~ .checkmark {
  @apply bg-white;
}

.label.dark:hover input ~ .checkmark {
  @apply bg-grey-darker;
}

.label input:checked ~ .checkmark {
  transition: all 0.5s ease;
}

.label.dark input:checked ~ .checkmark {
  @apply bg-grey-darker;
  transition: all 0.5s ease;
}

.checkmark:after {
  @apply hidden absolute;

  content: '';
}

.label input:checked ~ .checkmark:after {
  @apply block;
}

.label .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.label:hover .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.5s ease;
}

.label.light .checkmark:after {
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: white;
}

.label.dark .checkmark:after {
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: white;
}

.label.light:hover .checkmark:after {
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: black;
}

.label.dark:hover .checkmark:after {
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: white;
}
</style>
