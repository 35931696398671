<template>
  <div
    class="flex flex-wrap justify-center lg:justify-between items-center w-auto"
  >
    <div
      class="flex flex-wrap justify-center lg:justify-start w-full lg:w-1/2 pl-0 lg:pl-4 text-2xs uppercase"
    >
      <nuxt-link class="transition" to="/agbs"> AGBs </nuxt-link>
      <nuxt-link class="transition" to="/impressum"> Impressum </nuxt-link>
      <nuxt-link class="transition" to="/haftungsausschluss">
        Haftungsausschluss
      </nuxt-link>
      <nuxt-link class="transition" to="/datenschutz"> Datenschutz </nuxt-link>
    </div>
    <SafeCheckout class="flex justify-center lg:justify-end w-full lg:w-1/2" />
  </div>
</template>

<script>
import SafeCheckout from '~/components/SafeCheckout'
export default {
  components: {
    SafeCheckout,
  },
}
</script>

<style lang="scss" scoped>
a {
  @apply my-2 px-2 font-semibold text-grey-darker;

  &:hover {
    @apply text-red cursor-pointer;
  }
}
</style>
