<template>
  <div class="component-references">
    <template v-for="reference in references">
      <ContentCard
        v-if="reference.type === 'product'"
        :key="reference.id"
        :href="reference.url"
        :bestseller="reference.is_bestseller"
        :sale="reference.is_sale"
        class="wow fadeIn my-4"
      >
        <template slot="img">
          <img class="special-img-lg" :src="reference.image" alt="post image" />
        </template>
        <template slot="category">
          <span
            v-for="category in reference.groups"
            :key="category.id"
            class="category-tag"
            >{{ category.title }}</span
          >
        </template>
        <template slot="name">
          {{ reference.title }}
        </template>
        <!-- eslint-disable-next-line -->
        <div v-if="reference.summary" class="mt-4" v-html="reference.summary" />
        <template slot="price">
          {{ reference.price | currency }}
        </template>
        <template
          v-if="reference.price_old && reference.price_old > reference.price"
          slot="oldprice"
        >
          {{ reference.price_old | currency }}
        </template>
      </ContentCard>

      <!-- reference -->
      <ContentCard
        v-if="reference.type === 'reference'"
        :key="reference.id"
        is-blog-post
        :href="reference.url"
        :bestseller="reference.bestseller"
        class="wow fadeIn"
        data-wow-duration="0.5s"
      >
        <template slot="img">
          <img class="special-img-lg" :src="reference.image" alt="post image" />
        </template>
        <template slot="name">
          {{ reference.title }}
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div
          v-if="reference.summary"
          class="text-sm leading-custom mt-4"
          v-html="reference.summary"
        />
      </ContentCard>

      <ContentCard
        v-if="reference.type === 'blog-entry'"
        :key="reference.id"
        is-blog-post
        :href="reference.url"
        :bestseller="reference.bestseller"
        class="wow fadeIn"
        data-wow-duration="0.5s"
      >
        <template slot="img">
          <img class="special-img-lg" :src="reference.image" alt="post image" />
        </template>
        <template slot="name">
          {{ reference.title }}
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div
          v-if="reference.summary"
          class="text-sm leading-custom mt-4"
          v-html="reference.summary"
        />
      </ContentCard>
    </template>
  </div>
</template>

<script>
import ContentCard from '~/components/cardtypes/ContentCard'

export default {
  components: {
    ContentCard,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    references: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped></style>
