<template>
  <div :class="[{ 'agb-style': agb }]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    agb: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
h2,
h3,
h4,
a {
  @apply mb-4;

  line-height: 26px;
}

h2,
h3 {
  @apply font-semibold tracking-wide uppercase;
}

h4,
a {
  @apply font-semibold text-xs text-red uppercase;
}

a {
  &:hover {
    @apply text-black;

    cursor: pointer;

    .arrow {
      @apply border-black;

      transform: translate(5px) rotate(45deg);
    }
  }
}

p {
  @apply mb-6;
}

p,
li {
  @apply text-sm leading-custom;
}

p.intro {
  @apply font-semibold text-base;
}

p:last-of-type {
  margin-bottom: 0;
}

ul {
  @apply list-reset;
}

li::before {
  content: '–';
}
@screen lg {
  .title {
    @apply text-3xl;
  }
  .sub-title {
    @apply text-2xl;
  }
}

.agb-style {
  h3 {
    @apply tracking-normal normal-case;
  }

  a {
    @apply text-grey-darker normal-case;

    &:hover {
      @apply text-red;
    }
  }
}
</style>
