<template>
  <div @click="cartToggle">
    <div
      class="cart-button relative hidden lg:flex justify-center items-center w-10 h-10 ml-4 bg-grey rounded-full hover:bg-grey-darker hover:cursor-pointer transition"
    >
      <Cart class="dark transition" />
      <div
        v-if="totalItems > 0"
        class="counter flex justify-center items-center absolute bg-red rounded-full font-semibold text-white"
      >
        {{ totalItems }}
      </div>
      <transition tag="div" name="fade">
        <CartResume v-show="showCart" class="hidden lg:flex" />
      </transition>
    </div>
    <nuxt-link
      tag="div"
      to="/cartpage"
      class="block cart-button relative flex lg:hidden justify-center items-center w-10 h-10 ml-4 bg-grey rounded-full hover:bg-grey-darker hover:cursor-pointer transition"
    >
      <Cart class="dark transition" />
      <div
        v-if="totalItems > 0"
        class="counter flex justify-center items-center absolute bg-red rounded-full font-semibold text-white"
      >
        {{ totalItems }}
      </div>
      <transition tag="div" name="fade">
        <CartResume v-show="showCart" class="hidden lg:flex" />
      </transition>
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import Cart from '~/components/checkout/Cart'
import CartResume from '~/components/checkout/CartResume'

export default {
  components: {
    Cart,
    CartResume,
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('cart', ['totalItems']),
    ...mapState('cart', ['showCart']),
  },

  methods: {
    ...mapMutations('cart', ['cartOpen', 'cartClose', 'cartToggle']),
  },
}
</script>

<style lang="scss">
.cart-button:hover svg {
  fill: white;
}

.counter {
  top: -3px;
  right: -4px;
  min-width: 18px;
  height: 18px;
  font-size: 0.6875rem;
}
</style>
