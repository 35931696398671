// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ill/contact.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quickcontact[data-v-20b243a0] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") bottom center no-repeat;\n  background-size: contain;\n  height: calc(100vh - 86px);\n  overflow: auto;\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
