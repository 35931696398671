<template>
  <div>
    <!-- HEADER WITH MAINNAV: CONTACT-, WEBSITE- AND SERVICE-NAVIGATION -->

    <header>
      <div>
        <MenuMain />
      </div>
    </header>

    <section
      class="h-screen flex items-center justify-center flex-col text-grey-darker"
    >
      <div class="max-w-sm text-center">
        <Container>
          <h1 class="text-6xl">404</h1>
          <h2 class="my-6">
            Hoppla, wir konnten die gesuchte Seite nicht finden...
          </h2>
          <p class="leading-custom">
            Obwohl unsere Server unter Einsatz aller Kräfte nach der Seite
            gesucht haben, konnten wir sie nicht finden. Möglicherweise haben
            wir diese Seite gelöscht oder umbenannt, wir bitten um Verständnis.
          </p>
        </Container>
      </div>
    </section>
  </div>
</template>

<script>
import MenuMain from '~/components/menu/MenuMain'
import Container from '~/components/layoutelements/Container'

export default {
  components: {
    MenuMain,
    Container,
  },
}
</script>
