export const state = () => ({
  isOpen: false,
  dropdownIsOpen: false,
  pages: [],
  services: [],
  consulter: null,
  productCategories: [],
  bestsellers: [],
  locations: [],
  productSupplementsDescription: null,
  settings: [],
})

export const getters = {}

export const actions = {
  async load({ commit }) {
    const response = await this.$axios.$get('settings')
    commit('setPages', response.pages)
    commit('setServices', response.services)
    commit('setConsulter', response.consulter)
    commit('setProductCategories', response.product_categories)
    commit('setBestsellers', response.bestsellers)
    commit('setLocations', response.locations)
    commit(
      'setProductSupplemensDescription',
      response.product_supplements_description
    )
    commit('setSettings', response.settings)
  },
}

export const mutations = {
  setPages(state, pages) {
    state.pages = pages
  },

  setServices(state, services) {
    state.services = services
  },

  setConsulter(state, consulter) {
    state.consulter = consulter
  },

  setSolutions(state, solutions) {
    state.solutions = solutions
  },

  setBestsellers(state, bestsellers) {
    state.bestsellers = bestsellers
  },

  setLocations(state, locations) {
    state.locations = locations
  },

  setProductCategories(state, productCategories) {
    state.productCategories = productCategories
  },

  setProductSupplemensDescription(state, description) {
    state.productSupplementsDescription = description
  },

  setSettings(state, settings) {
    state.settings = settings
  },

  open(state) {
    state.isOpen = true
  },

  close(state) {
    state.isOpen = false
  },

  toggleNavigation(state) {
    state.isOpen = !state.isOpen
  },
}
