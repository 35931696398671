<template>
  <!-- A CARDTYPE FOR HIGHLIGHTED CONTENT ON CONTENT PAGE - COULD BE A BLOGPOST OR A PRODUCT -->

  <nuxt-link
    class="article-card block relative overflow-hidden w-full bg-grey-lighter my-2 hover:bg-black hover:text-white hover:cursor-pointer transition"
    :class="[{ 'h-auto mb-4': isBlogPost }, { 'lg:h-72': !isBlogPost }]"
    :to="href"
  >
    <SaleTag :sale="sale" :bestseller="bestseller" :news="news" />

    <!-- HOVER EFFECT -->

    <div
      class="border-hover-black absolute z-80 w-full h-full transition-fast"
    />

    <!-- CONTENT -->

    <div class="flex flex-wrap h-full p-6">
      <div
        class="relative overflow-hidden w-full mr-0"
        :class="[
          { 'lg:w-full h-48 sm:h-100 lg:h-64 lg:mr-0 mb-3': isBlogPost },
          { 'w-full h-60 lg:w-1/2 lg:h-full lg:mr-6': !isBlogPost },
        ]"
      >
        <slot name="img" />
      </div>
      <Paragraph
        class="w-full lg:w-1/2"
        :class="[{ 'lg:w-full': isBlogPost }, { 'lg:w-1/2': !isBlogPost }]"
      >
        <h4 class="flex flex-wrap">
          <span class="mr-2"><slot name="category" /></span>
        </h4>
        <h3>
          <slot name="name" />
        </h3>
        <div>
          <span class="font-semibold text-grey-darker">
            <slot name="price" />
          </span>
          <span class="text-blue-light line-through">
            <slot name="oldprice" />
          </span>
        </div>
        <slot />
      </Paragraph>
    </div>
  </nuxt-link>
</template>

<script>
import Paragraph from '~/components/Paragraph'
import SaleTag from '~/components/SaleTag'

export default {
  components: {
    Paragraph,
    SaleTag,
  },

  props: {
    sale: {
      type: Boolean,
      default: false,
    },
    bestseller: {
      type: Boolean,
      default: false,
    },
    news: {
      type: Boolean,
      default: false,
    },
    shop: {
      type: Boolean,
      default: false,
    },
    isBlogPost: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
// .article-card:last-of-type {
//   margin-bottom: 0;
// }
</style>
