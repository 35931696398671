<template>
  <div class="newsletter w-full pb-30 lg:pb-21 pt-16 md:pt-21 bg-grey-light">
    <Container class="uppercase text-center">
      <div
        class="mb-4 mx-auto max-w-heading uppercase text-md text-red letter tracking-wide font-semibold"
      >
        Newsletter
      </div>
      <div
        class="mb-8 mx-auto max-w-heading text-2xl font-semibold leading-tight uppercase"
      >
        News, Sales und unsere besten Angebote. Direkt in Ihrem Postfach
      </div>
      <NewsletterForm />
    </Container>
  </div>
</template>

<script>
import Container from '~/components/layoutelements/Container'
import NewsletterForm from '~/components/NewsletterForm'

export default {
  components: {
    Container,
    NewsletterForm,
  },
}
</script>

<style lang="scss" scoped>
.newsletter {
  background-image: url('../assets/images/ill/wave.svg');
  background-position: bottom;
  background-size: cover;
}

.max-w-heading {
  max-width: 37rem;
}
</style>
