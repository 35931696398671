<template>
  <div
    class="absolute pin-t pin-r z-85 py-4 px-5 rounded-sm font-semibold text-2xs tracking-wide uppercase text-white"
    :class="[
      { 'bg-beo-yellow': sale },
      { 'bg-beo-yellow': bestseller },
      { 'bg-blue': news },
    ]"
  >
    <span v-if="sale"> einmaliges Angebot </span>
    <span v-if="bestseller"> Bestseller </span>
    <span v-if="news"> Neu </span>
  </div>
</template>

<script>
export default {
  props: {
    sale: {
      type: Boolean,
      default: false,
    },
    bestseller: {
      type: Boolean,
      default: false,
    },
    news: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
