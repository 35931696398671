<template>
  <!-- A CARDTYPE FOR CONTACT INFORMATION ABOUT A SHOP -->

  <div
    class="my-3 px-5 pt-5 pb-0 sm:p-5 rounded-sm font-semibold uppercase"
    :class="[{ 'bg-black': dark }, { 'bg-grey-lighter': !dark }]"
  >
    <div>
      <h3
        class="mb-2 font-semibold text-sm"
        :class="[{ 'text-white': dark }, { '': !dark }]"
      >
        <a :href="location.url" target="_blank">
          <slot name="title" />
        </a>
      </h3>
      <div class="flex flex-wrap sm:flex-no-wrap justify-between">
        <div :class="{ 'highlight-contact-link w-full sm:w-1/2': highlight }">
          <p
            class="font-semibold text-xs"
            :class="[{ 'text-grey': dark }, { 'text-grey-darker': !dark }]"
          >
            <a :href="location.url" target="_blank">
              <slot name="street" />
            </a>
          </p>
          <p
            class="font-semibold text-xs"
            :class="[{ 'text-grey': dark }, { 'text-grey-darker': !dark }]"
          >
            <a :href="location.url" target="_blank">
              <slot name="town" />
            </a>
          </p>
        </div>
        <div class="contact-links w-full sm:w-1/2">
          <div
            :class="[{ 'hours-dark': dark }, { hours: !dark }]"
            class="hours flex justify-start sm:justify-end items-center py-6 sm:py-3 lg:py-1 px-0 sm:px-4 lg:px-0 lg:bg-transparent font-semibold text-xs text-red hover:cursor-pointer transition"
            @click="showCollapse = !showCollapse"
          >
            <span class="mr-2">Öffnungszeiten</span>
            <Arrow
              class="arrow red transition"
              :class="[showCollapse ? 'down' : 'up']"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- OPENING HOURS -->

    <transition tag="div" name="slide-down" mode="in-out">
      <div
        v-show="!showCollapse"
        class="pb-4 sm:pt-3 text-xs normal-case"
        :class="[dark ? 'text-white' : 'text-grey-darker']"
      >
        <div
          v-for="(time, index) in location.times"
          :key="index"
          class="flex justify-between py-1"
        >
          <strong>{{ time.key }}</strong>
          {{ time.value }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow'

export default {
  components: {
    Arrow,
  },

  props: {
    location: {
      type: Object,
      required: true,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showCollapse: true,
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  @apply py-1;
}

.hours {
  &:hover {
    @apply text-black;

    .arrow {
      @apply border-black;
    }
  }
}

.hours-dark {
  &:hover {
    @apply text-white;

    .arrow {
      @apply border-white;
    }
  }
}
.arrow {
  width: 0.35rem;
  height: 0.35rem;
  margin-bottom: 3px;
}

.contact-links {
  align-self: flex-end;
}

.highlight-contact-link {
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='30' viewBox='0 0 19 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 0C4.253 0 0 4.197 0 9.375c0 1.399.312 2.728.867 3.918L8.103 28.79C8.45 29.535 8.947 30 9.5 30s1.05-.465 1.397-1.208l7.236-15.499c.557-1.19.867-2.52.867-3.918C19 4.197 14.747 0 9.5 0zm-.38 12.75c-2.098 0-3.8-1.68-3.8-3.75 0-2.07 1.702-3.75 3.8-3.75s3.8 1.68 3.8 3.75c0 2.07-1.702 3.75-3.8 3.75z' fill='%233C5174' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: 10px center;
  @apply bg-no-repeat bg-grey p-2 pl-9 pr-5 rounded-sm mr-2 flex-1;
}
</style>
