<template>
  <div class="relative">
    <client-only>
      <carousel
        ref="carousel"
        class="content-carousel relative bg-grey"
        :autoplay="false"
        :autoplay-timeout="6000"
        :autoplay-hover-pause="true"
        :loop="true"
        :per-page="1"
        :pagination-enabled="true"
      >
        <slide v-for="(image, index) in images" :key="index">
          <AppCarouselSlide>
            <img class="special-img-lg" :src="image" alt="post image" />
          </AppCarouselSlide>
        </slide>
      </carousel>
    </client-only>

    <!-- NAVIGATION -->

    <div
      class="content-carousel slider-nav hidden lg:flex flex-col absolute pin-b pin-r m-10"
    >
      <div class="prev transition" @click.prevent="prevSlide">
        <Arrow class="arrow white left" />
      </div>
      <div class="next transition" @click.prevent="nextSlide">
        <Arrow class="arrow white right" />
      </div>
    </div>
  </div>
</template>

<script>
import AppCarouselSlide from '~/components/carousel/AppCarouselSlide'
import Arrow from '~/components/Arrow'

export default {
  components: {
    AppCarouselSlide,
    Arrow,
  },

  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    },
  },
}
</script>

<style lang="scss">
.content-carousel.slider-nav {
  width: 80px;

  .prev,
  .next {
    @apply pt-2 pb-3 bg-black rounded-sm opacity-85;

    &:hover {
      @apply opacity-100 cursor-pointer;
    }
  }

  .prev {
    @apply pl-3 pr-4;

    align-self: flex-start;
  }

  .next {
    @apply pl-3 pr-4;

    align-self: flex-end;
  }
}

.content-carousel .VueCarousel-pagination {
  bottom: 0;
}

.content-carousel .VueCarousel-dot {
  background-color: white !important;

  &:focus {
    outline: none !important;
  }
}

.content-carousel .VueCarousel-dot--active {
  background-color: black !important;
}
</style>
