<template>
  <div>
    <nuxt />

    <!-- CONTACT FOOTER -->
    <ContactFooter />
    <ProductSupplements />
  </div>
</template>

<script>
import ContactFooter from '~/components/sections/ContactFooter'
import ProductSupplements from '~/components/popups/ProductSupplements'

export default {
  components: {
    ContactFooter,
    ProductSupplements,
  },
}
</script>

<style></style>
