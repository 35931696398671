import Vue from 'vue'
import VueGtag from 'vue-gtag'

/**
 * @type {import('@nuxt/types').Plugin}
 */
const vueGtag = ({ app }) => {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.NUXT_ENV_GA4_ID },
      pageTrackerTemplate(to) {
        return {
          page_title: to.path,
          page_path: to.path,
          page_location: window.location.href,
        }
      }
    },
    app.router
  )
}

export default vueGtag