<template>
  <nuxt-link
    :to="href"
    class="inline-block w-full lg:w-auto bg-black rounded-sm hover:bg-grey-darker py-4 px-8 font-semibold text-xs uppercase text-white text-center transition hover:cursor-pointer focus:outline-none"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
  },
}
</script>
