<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <Component :is="tag" :bind="$attrs" v-html="content" />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },

    tag: {
      type: String,
      default: 'div',
    },

    // TODO: Simplify map definition but keep flexibility.
    map: {
      type: Object,
      default: () => ({
        p: {
          tag: 'p',
          classList: '',
        },

        h1: {
          tag: 'h2',
          classList:
            'uppercase tracking-wide text-lg mt-2 mb-4 font-semibold text-blue-darker',
        },

        h2: {
          tag: 'h3',
          classList:
            'uppercase tracking-wide text-base mt-2 mb-4 font-semibold text-blue-darker',
        },

        h3: {
          tag: 'p',
          classList: '',
        },

        h4: {
          tag: 'p',
          classList: '',
        },

        h5: {
          tag: 'p',
          classList: '',
        },

        h6: {
          tag: 'p',
          classList: '',
        },
      }),
    },
  },

  computed: {
    content() {
      return this.mapTags(this.text, this.map)
    },
  },

  methods: {
    mapTags(html, map = {}) {
      if (Object.keys(map).length <= 0) {
        return html
      }

      // TODO: Respect existing class attribute on the tag.

      return html.replace(
        /<(\/?)([^\s>]+)(“[^”]*”|'[^’]*’|[^'”>])*>/g,
        (match, endTagSymbol, tag) => {
          let config = map[tag]

          if (typeof config === 'string') {
            config = {
              tag: config,
            }
          }

          if (!config) {
            console.log({ match, endTagSymbol, tag, config })
            return match
          }

          return match.replace(new RegExp(`<${endTagSymbol}${tag}`), () => {
            if (endTagSymbol) {
              return `<${endTagSymbol}${config.tag}`
            }

            return (
              `<${config.tag}` +
              (config.classList ? ` class="${config.classList}"` : '')
            )
          })
        }
      )
    },
  },
}
</script>
<style scoped>
p {
  @apply text-sm mt-4 leading-normal;
}
</style>
