<template>
  <form
    id="mc-embedded-subscribe-form"
    class="w-full md:w-3/5 md:m-auto px-6"
    action="//media-line.us14.list-manage.com/subscribe/post?u=a7b0d2fcc3e5dba737c6c30a6&amp;id=9aff722731"
    method="post"
  >
    <div class="flex flex-wrap justify-between">
      <input
        type="text"
        style="position: absolute; left: -5000px"
        aria-hidden="true"
        name="b_a7b0d2fcc3e5dba737c6c30a6_9aff722731"
        tabindex="-1"
        value=""
      />

      <div class="inline-block relative w-full md:w-1/2-2 mb-4">
        <select
          id="mce-ANREDE"
          v-model="title"
          name="ANREDE"
          required=""
          class="block appearance-none w-full px-3 py-3 pr-8 rounded-sm text-xs text-grey-darkest focus:text-black focus:outline-none"
          :class="{ placeholder: !title }"
        >
          <option v-if="!title" value="" disabled selected hidden>
            Anrede
          </option>
          <option v-for="option of options" :key="option">
            {{ option }}
          </option>
        </select>
        <div
          class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darkest"
          :class="{ placeholder: !title }"
        >
          <svg
            class="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>

      <input
        id="mce-FNAME"
        type="text"
        name="FNAME"
        placeholder="Vorname"
        required=""
        class="w-full md:w-1/2-2 mb-4 py-3 px-3 outline-none rounded-sm text-xs text-grey-darkest focus:text-black"
      />

      <input
        id="mce-LNAME"
        type="text"
        name="LNAME"
        placeholder="Nachname"
        required=""
        class="w-full md:w-1/2-2 mb-4 py-3 px-3 outline-none rounded-sm text-xs text-grey-darkest focus:text-black"
      />

      <input
        id="mce-EMAIL"
        type="email"
        name="EMAIL"
        placeholder="max.muster@mail.ch"
        required=""
        class="w-full md:w-1/2-2 mb-4 py-3 px-3 outline-none rounded-sm text-xs text-grey-darkest focus:text-black"
      />
    </div>

    <div class="flex justify-end">
      <button
        type="submit"
        class="h-10 px-10 bg-red rounded-sm text-white uppercase text-center hover:bg-black text-sm font-semibold transition"
      >
        Abonnieren
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      options: ['Herr', 'Frau'],
      title: '',
    }
  },
}
</script>

<style scoped>
.placeholder {
  @apply text-grey-dark;
}

input::placeholder {
  @apply opacity-100;
  @apply text-grey-dark;
}
</style>
