<template>
  <!-- ONLY FOR MOBILE -->
  <ul class="list-reset flex lg:hidden flex-col mt-6">
    <li
      v-for="page in pages"
      :key="page.id"
      class="my-2 bg-white hover:bg-grey rounded-sm transition"
    >
      <nuxt-link class="flex items-center py-3 px-4" :to="page.url">
        <div class="w-8 mr-3">
          <img class="w-full" :src="page.icon" alt="service icon" />
        </div>
        <h3 class="font-semibold text-sm text-grey-darker uppercase">
          {{ page.title }}
        </h3>
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},

  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
}
</script>
