import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e8ad238a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _0274f245 = () => interopDefault(import('../pages/cartpage.vue' /* webpackChunkName: "pages/cartpage" */))
const _6b4f6348 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _a508bb62 = () => interopDefault(import('../pages/marken/index.vue' /* webpackChunkName: "pages/marken/index" */))
const _3b4e22f3 = () => interopDefault(import('../pages/referenzen/index.vue' /* webpackChunkName: "pages/referenzen/index" */))
const _7656ad3e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0d62008f = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _d0105df4 = () => interopDefault(import('../pages/ueber-uns.vue' /* webpackChunkName: "pages/ueber-uns" */))
const _3c21761e = () => interopDefault(import('../pages/bestellung/_slug.vue' /* webpackChunkName: "pages/bestellung/_slug" */))
const _ec1a661a = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _98192634 = () => interopDefault(import('../pages/dienstleistungen/_slug.vue' /* webpackChunkName: "pages/dienstleistungen/_slug" */))
const _a875fdf2 = () => interopDefault(import('../pages/marken/_slug.vue' /* webpackChunkName: "pages/marken/_slug" */))
const _399781ab = () => interopDefault(import('../pages/referenzen/_slug.vue' /* webpackChunkName: "pages/referenzen/_slug" */))
const _0bab5f47 = () => interopDefault(import('../pages/shop/_slug.vue' /* webpackChunkName: "pages/shop/_slug" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _11ba0700 = () => interopDefault(import('../pages/_site.vue' /* webpackChunkName: "pages/_site" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _e8ad238a,
    name: "blog"
  }, {
    path: "/cartpage",
    component: _0274f245,
    name: "cartpage"
  }, {
    path: "/checkout",
    component: _6b4f6348,
    name: "checkout"
  }, {
    path: "/marken",
    component: _a508bb62,
    name: "marken"
  }, {
    path: "/referenzen",
    component: _3b4e22f3,
    name: "referenzen"
  }, {
    path: "/search",
    component: _7656ad3e,
    name: "search"
  }, {
    path: "/shop",
    component: _0d62008f,
    name: "shop"
  }, {
    path: "/ueber-uns",
    component: _d0105df4,
    name: "ueber-uns"
  }, {
    path: "/bestellung/:slug?",
    component: _3c21761e,
    name: "bestellung-slug"
  }, {
    path: "/blog/:slug",
    component: _ec1a661a,
    name: "blog-slug"
  }, {
    path: "/dienstleistungen/:slug?",
    component: _98192634,
    name: "dienstleistungen-slug"
  }, {
    path: "/marken/:slug",
    component: _a875fdf2,
    name: "marken-slug"
  }, {
    path: "/referenzen/:slug",
    component: _399781ab,
    name: "referenzen-slug"
  }, {
    path: "/shop/:slug",
    component: _0bab5f47,
    name: "shop-slug"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/:site",
    component: _11ba0700,
    name: "site"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
