<template>
  <div class="fixed pin-t z-1025 w-full">
    <div class="relative z-1020">
      <!-- MENU CONTACT -->
      <div class="bg-grey">
        <MenuContact v-model="contactOpen" />
      </div>

      <!-- MAIN MENU -->
      <div
        class="overflow-y-auto lg:overflow-visible bg-grey-lighter transition scrolling-touch"
      >
        <Container>
          <!-- WEBSITE NAVIGATION -->
          <nav
            class="flex flex-wrap lg:flex-no-wrap justify-between items-center"
          >
            <div
              class="flex flex-grow lg:flex-no-grow w-full lg:w-auto justify-between items-center"
            >
              <!-- LOGO - HOME BUTTON -->
              <LogoButton
                class="my-4 lg:my-0 mr-3"
                :class="{ 'lg:hidden xl:block': searchActive }"
              />
              <div class="flex items-center">
                <!-- CART BUTTON -->

                <CartImage class="flex lg:hidden mr-5" />

                <!-- BURGERBUTTON ONLY FOR MOBILE NAVIGATION -->
                <BurgerButton v-model="open" />
              </div>
            </div>

            <!-- MOBILE NAVIGATION BAR -->
            <MenuNavbar class="lg:hidden" collapsible :open="open" />

            <!-- DESKTOP NAVIGATION BAR -->
            <MenuNavbar
              class="hidden lg:flex"
              :open="open"
              @mouseenter="activateMenu('hover')"
              @mouseleave="activateMenu('normal')"
            />
          </nav>
        </Container>
      </div>
    </div>

    <!-- MENU SERVICE ONLY FOR DESKTOP -->
    <!-- <transition tag="div" name="slide-down">
      <div v-show="isActive('hover')" class="sm:hidden lg:block absolute w-full h-screen z-1015 bg-white-opacity" @mouseenter="activateMenu('hover')" @mouseleave="activateMenu('normal')">
        <div class="bg-grey-light py-15" @mouseleave="activateMenu('normal')">
          <Container>
            <MenuService class="-mx-2" />
          </Container>
        </div>
      </div>
    </transition> -->

    <!-- MENU CONTACT ONLY FOR MOBILE -->
    <transition tag="div" name="slide-down">
      <div v-if="contactOpen" class="bg-grey-light w-full h-screen lg:hidden">
        <QuickContact />
      </div>
    </transition>
  </div>
</template>

<script>
import Container from '~/components/layoutelements/Container'
import MenuNavbar from '~/components/menu/MenuNavbar'
import MenuContact from '~/components/menu/MenuContact'
import LogoButton from '~/components/LogoButton'
// import MenuService from '~/components/menu/MenuService'
import BurgerButton from '~/components/menu/BurgerButton'
import QuickContact from '~/components/menu/QuickContact'
import CartImage from '~/components/checkout/CartImage'

export default {
  components: {
    Container,
    MenuNavbar,
    MenuContact,
    LogoButton,
    // MenuService,
    BurgerButton,
    QuickContact,
    CartImage,
  },
  data() {
    return {
      activeMenu: 'normal',
      open: false,
      contactOpen: false,
      searchActive: false,
    }
  },

  mounted() {
    this.$nuxt.$on('search-focused', () => {
      this.searchActive = true
    })
    this.$nuxt.$on('search-blurred', () => {
      setTimeout(() => {
        this.searchActive = false
      }, 350)
    })
  },

  destroyed() {
    this.$nuxt.$off('search-focused')
    this.$nuxt.$off('search-blurred')
  },

  methods: {
    isActive(menu) {
      return this.activeMenu === menu
    },
    activateMenu(menu) {
      this.activeMenu = menu
    },
  },
}
</script>
