<template>
  <div>
    <client-only>
      <GmapMap
        :center="{ lat: locations[1].latitude, lng: locations[1].longitude }"
        :options="options"
        :zoom="9"
        class="w-full h-full"
      >
        <GmapMarker
          v-for="(location, index) in locations"
          :key="index"
          :position="{ lat: location.latitude, lng: location.longitude }"
          :draggable="false"
          icon="/images/photo/marker.png"
          @click="openLocation(location)"
        />
      </GmapMap>
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MapStyle from '~/constants/MapStyle'

export default {
  data() {
    return {
      options: {
        mapTypeControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        styles: MapStyle,
      },
    }
  },
  computed: {
    ...mapState('settings', ['locations']),
  },
  methods: {
    openLocation(location) {
      window.open(location.url, '_blank')
    },
  },
}
</script>

<style lang="scss"></style>
