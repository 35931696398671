<template>
  <div
    class="flex justify-end lg:hidden focus:outline-none my-0 py-2 px-0 font-semibold text-2xs uppercase text-grey-darker mr-2"
    @click="$emit('input', !value)"
  >
    <span v-if="!value" class="transition"> Kontakt & Öffnungszeiten </span>
    <span v-else class="transition">
      <Arrow class="arrow left transition" /> Zurück
    </span>
  </div>
</template>

<script>
import Arrow from '~/components/Arrow'

export default {
  name: 'ContactButton',
  components: {
    Arrow,
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
span {
  .arrow {
    @apply border-grey-darker;
  }

  &:hover {
    @apply text-red cursor-pointer;

    .arrow {
      @apply border-red;
    }
  }
}
</style>
