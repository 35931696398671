<template>
  <div class="bubble-container w-100 absolute">
    <div
      class="bubble relative w-100 p-3 bg-grey-lighter rounded-sm"
      @mouseleave="cartClose"
    >
      <h2 class="font-semibold text-lg tracking-wide uppercase">Warenkorb</h2>

      <template v-if="cartList && cartList.length > 0">
        <CartItem
          v-for="product in cartList"
          :key="product.skuId"
          :product="product"
        />

        <div
          v-if="isValidVoucher"
          class="flex justify-between my-6 px-2 font-medium text-xs"
        >
          <span class="text-grey-darker"> Gutschein </span>
          <span class="text-grey-darker">{{
            activeVoucher.voucherPrice | currency
          }}</span>
        </div>

        <div
          class="flex justify-between my-6 px-2 font-semibold text-xs uppercase"
        >
          <span class="text-black">Total</span>
          <span class="text-blue-dark">{{ total | currency }}</span>
        </div>

        <ClassicButtonLink href="/cartpage" class="buy" @click="cartClose">
          Warenkorb anzeigen
        </ClassicButtonLink>
      </template>
      <template v-else>
        <p class="my-3">Noch keine Produkte im Warenkorb</p>
        <ClassicButtonLink href="/shop" class="buy mb-2">
          Zum Shop
        </ClassicButtonLink>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import ClassicButtonLink from '~/components/ClassicButtonLink'
import CartItem from '~/components/checkout/CartItem'

export default {
  components: {
    ClassicButtonLink,
    CartItem,
  },
  computed: {
    ...mapGetters('cart', [
      'cartList',
      'total',
      'totalExclVAT',
      'isValidVoucher',
      'activeVoucher',
    ]),
    ...mapState('cart', ['showCart']),
  },

  watch: {
    cartList() {
      this.calculateCartPrice()
    },
  },

  methods: {
    ...mapActions('cart', ['calculateCartPrice']),
    ...mapMutations('cart', ['cartOpen', 'cartClose', 'cartToggle']),
  },
}
</script>

<style lang="scss">
.bubble-container {
  top: 52px;
  right: 0;
}

.bubble {
  border-color: #c8cfdb;
  border-style: solid;
  border-width: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

  &:after {
    position: absolute;
    top: -5px;
    right: 11px;
    content: '';
    border-color: transparent #e5e8ee #e5e8ee transparent;
    border-style: solid;
    border-width: 5px;
    border-radius: 0.125rem;
    transform: rotate(225deg);
  }
  &:before {
    position: absolute;
    top: -10px;
    right: 8px;
    content: '';
    border-color: transparent #c8cfdb #c8cfdb transparent;
    border-style: solid;
    border-width: 8px;
    border-radius: 0.125rem;
    transform: rotate(225deg);
  }
  .buy {
    width: 100%;
  }
}
</style>
