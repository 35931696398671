<template>
  <div ref="svgElement" class="svg-logo" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const url =
      'https://asset-proxy.novu.ch/api/fetch/?url=' +
      encodeURIComponent(this.src)

    this.$axios.get(url, { responseType: 'text' }).then((response) => {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(response.data, 'text/xml')
      const svg = xmlDoc.getElementsByTagName('svg')[0]
      const oldClasses = this.$refs.svgElement.getAttribute('class')

      if (!svg) {
        console.error('No SVG found at ' + this.src)
        return
      }

      svg.removeAttribute('xmlns:a')
      svg.setAttribute('class', oldClasses)

      if (
        !svg.getAttribute('viewBox') &&
        svg.getAttribute('height') &&
        svg.getAttribute('width')
      ) {
        svg.setAttribute(
          'viewBox',
          '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width')
        )
      }

      this.$refs.svgElement.parentNode.replaceChild(svg, this.$refs.svgElement)
    })
  },
}
</script>

<style lang="scss">
svg.svg-logo {
  fill: white;
}
</style>
