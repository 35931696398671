<template>
  <div class="w-full flex justify-center mt-8">
    <iframe
      class="mx-auto w-full timify-widget rounded-sm"
      :src="timifyWidgetUrl"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},

  props: {
    title: {
      type: String,
      default: '',
    },
    timifyServices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      accountId: process.env.timifyAccountId,
      locale: 'de-ch',
    }
  },

  computed: {
    ...mapState('settings', ['settings']),

    timifyWidgetUrl() {
      console.log(JSON.stringify(this.services))
      return `https://book.timify.com/services?${this.locale}&accountId=${this.accountId}&showServices=${this.services}`
    },
    services() {
      if (!this.timifyServices.length) {
        return this.settings?.timifyServices.join(',')
      }
      return this.timifyServices.join(',')
    },
  },
}
</script>

<style lang="scss" scoped>
.timify-widget {
  height: 500px;
  max-width: 500px;
}
</style>
