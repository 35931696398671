<template>
  <div>
    <!-- HEADER WITH MAINNAV: CONTACT-, WEBSITE- AND SERVICE-NAVIGATION -->

    <header>
      <div>
        <MenuMain />
      </div>
    </header>

    <section
      class="h-screen flex items-center justify-center flex-col text-grey-darker"
    >
      <div class="max-w-sm text-center">
        <Container>
          <h1 class="text-6xl">500</h1>
          <h2 class="my-6">Hoppla, ein Fehler ist aufgetreten...</h2>
        </Container>
      </div>
    </section>
  </div>
</template>

<script>
import MenuMain from '~/components/menu/MenuMain'
import Container from '~/components/layoutelements/Container'

export default {
  components: {
    MenuMain,
    Container,
  },
}
</script>
