<template>
  <button
    class="book-btn timify-button"
    :class="{ 'force-long-text': forceLongText }"
    :data-account-id="accountId"
    :data-show-services="services"
  >
    Beratungstermin <span class="lg:inline hidden">buchen</span>
  </button>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BookAppointmentButton',
  components: {},
  props: {
    forceLongText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accountId: process.env.timifyAccountId,
    }
  },

  computed: {
    ...mapState('settings', ['settings']),

    services() {
      return this.settings?.timifyServices.join(',')
    },
  },

  mounted() {
    // Reinitialize global timify object on mount
    // because timify script stops working after pageLoad
    setTimeout(() => {
      if (typeof window.TimifyWidget?.init === 'function') {
        window.TimifyWidget.init()
      }
    }, 100)
  },
}
</script>

<style lang="scss" scoped>
button {
  @apply py-2;
}

.book-btn {
  @apply bg-black text-white uppercase px-4 font-bold;
  min-height: 1.875rem;
}

.book-btn.force-long-text span {
  display: inline !important;
}
</style>
