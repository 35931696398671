// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/ill/bg-wave.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/ill/advice.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".consulting[data-v-5daa812a] {\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n.consulting-small[data-v-5daa812a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: top center;\n}\n.consulting-large[data-v-5daa812a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: bottom right;\n}\na[data-v-5daa812a] {\n  display: block;\n  padding-top: .25rem;\n  padding-bottom: .25rem;\n  color: #fff;\n}\na[data-v-5daa812a]:hover {\n  color: #f5000d;\n}\nimg[data-v-5daa812a] {\n  height: 280px;\n  right: -120px;\n  bottom: -50px;\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
