import Vue from 'vue'

Vue.filter('currency', (value) => {
  if (!value) return ''
  return (
    'CHF ' +
    value.toLocaleString('de-CH', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  )
})
