import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'

export default ({ app }) => {
  Vue.use(VueGtm, {
    id: process.env.NUXT_ENV_GTM_ID,
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false,
    enabled: true,
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true,
    vueRouter: app.router,
    trackOnNextTick: false,
  })
}
