<template>
  <div class="components">
    <component
      :is="component.component_name"
      v-for="component in components"
      :key="component.id"
      v-bind="component"
    />
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    components: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {},
}
</script>
