<template>
  <div
    class="flex items-center justify-center lg:justify-end bg-grey lg:bg-transparent font-semibold text-2xs uppercase text-black"
  >
    <div class="flex items-center pr-3">
      <span class="hidden lg:block ml-6">Safe-Checkout provided by</span>
      <img class="h-4 pl-3" src="~assets/images/ill/six.svg" />
    </div>
    <div class="flex items-center pl-2 pr-0 lg:pr-4 bg-light">
      <img class="h-6" src="~assets/images/ill/pay_mastercard.svg" />
      <img class="h-6" src="~assets/images/ill/pay_visa.svg" />
      <img class="h-6" src="~assets/images/ill/pay_twint.svg" />
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   dark: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
}
</script>

<style lang="scss" scoped>
img {
  @apply my-2 mx-2;
}

.bg-light {
  background: linear-gradient(
    to right,
    rgba(200, 207, 219, 1) 0%,
    rgba(163, 175, 195, 0) 100%
  );
}

// .bg-dark {
//   background: linear-gradient(
//     to right,
//     rgba(163, 175, 195, 1) 0%,
//     rgba(200, 207, 219, 0) 100%
//   );
// }
</style>
