<template>
  <!-- eslint-disable-next-line -->
  <div class="wysiwyg" :class="$route.params.site" v-html="content" />
</template>

<script>
// import Paragraph from '~/components/Paragraph'

export default {
  components: {
    // Paragraph,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss"></style>
