<template>
  <div v-click-outside="resetQuery" class="relative bg-white">
    <input
      ref="searchInput"
      v-model="query"
      type="text"
      class="flex items-center py-3 w-full pl-3 pr-10 ie-search border-2 border-solid border-grey outline-none text-xs text-grey focus:text-black"
      placeholder="Suche nach Produkten, Kategorien, Marken usw. "
      @keydown="keydown($event)"
      @input="search($event)"
      @focus="$nuxt.$emit('search-focused')"
      @blur="$nuxt.$emit('search-blurred')"
    />
    <div
      class="flex justify-end items-center mr-3 absolute pin-t pin-r w-16 h-full"
    >
      <button
        v-if="hasTyped"
        type="button"
        class="w-4 h-full text-center focus:outline-none"
        @click="resetQueryAndFocus"
      >
        <span class="ie-search flex justify-center items-center">
          <img
            class="w-full"
            src="~assets/images/ill/cross.svg"
            alt="search icon"
          />
        </span>
      </button>
      <button
        v-if="!query || !query.length"
        type="button"
        class="w-5 h-full text-center focus:outline-none"
      >
        <span class="flex justify-center items-center">
          <img
            class="w-full"
            src="~assets/images/ill/search.svg"
            alt="search icon"
          />
        </span>
      </button>
    </div>
    <transition tag="div" name="slide-down" mode="in-out">
      <SearchDropdown
        v-if="hasTyped && open && !loading"
        :results="results"
        :selected-element="selectedElement"
      />
      <SearchDropdown v-if="loading" loading />
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import SearchDropdown from '~/components/dropdown/SearchDropdown'

export default {
  components: {
    SearchDropdown,
  },

  data() {
    return {
      open: false,
      query: '',
      results: [],
      loading: false,
      selectedElement: 0,
    }
  },

  computed: {
    hasTyped() {
      return this.query && this.query.length > 0
    },
  },

  methods: {
    close() {
      this.open = false
    },
    doRequest: debounce((self) => {
      console.log('searching: ' + self.query)
      self.$axios.$get('/settings?search=' + self.query).then((response) => {
        self.results = response.items || []
        self.loading = false
        self.selectedElement = 0
      })
    }, 500),

    keydown($event) {
      if ($event.keyCode === 13 && this.results.length > 0) {
        if (this.selectedElement === 0) {
          this.$router.push(this.results[0].url)
        }

        if (this.results[this.selectedElement - 1]) {
          this.$router.push(this.results[this.selectedElement - 1].url)
        }

        return
      }

      // key down
      if ($event.keyCode === 38) {
        this.open = true
        if (!this.selectedElement) {
          this.selectedElement = 1
        } else if (this.selectedElement > 0) {
          this.selectedElement = this.selectedElement - 1
        }
        return
      }

      // key up
      if ($event.keyCode === 40) {
        this.open = true

        if (!this.selectedElement) {
          this.selectedElement = 1
        } else if (this.results.length > this.selectedElement) {
          this.selectedElement = this.selectedElement + 1
        }
        return
      }

      // escape
      if ($event.keyCode === 27) {
        this.open = false
      }
    },

    search($event) {
      this.loading = true
      this.open = true
      this.doRequest(this)
    },
    resetQuery() {
      this.query = ''
      this.open = false
    },
    resetQueryAndFocus() {
      this.resetQuery()
      setTimeout(() => {
        this.$refs.searchInput.focus()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  border-left-width: 0;
}
.search-dropdown {
  top: 100%;
}

// target Internet Explorer
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  input.ie-search {
    padding-right: 10px;
  }
  span.ie-search {
    display: none;
  }
}
</style>
