<template>
  <div class="max-w-4xl mx-auto" :class="[{ 'px-4': !fixWidth }]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fixWidth: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
