var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"href":"/"}},[_c('div',{staticClass:"relative w-full overflow-hidden",class:[
      {
        'flex flex-col items-center h-screen lg:h-tqscreen bg-black nav-space':
          _vm.home,
      },
      { 'h-120': !_vm.home },
    ]},[_c('div',{staticClass:"relative w-full h-full flex justify-center items-center"},[_vm._t("default")],2),_vm._v(" "),(_vm.home)?_c('div',{staticClass:"lg:absolute pin-b pin-l max-w-full md:max-w-sm m-4 lg:m-20 p-10 bg-white rounded-sm z-100"},[_c('Paragraph',[_c('h2',[_vm._t("title")],2),_vm._v(" "),_c('p',[_vm._t("paragraph")],2)]),_vm._v(" "),_vm._t("button")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }