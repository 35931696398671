<template>
  <div class="flex justify-between lg:max-w-4xl lg:mx-auto lg:px-4">
    <ul class="list-reset justify-start text-2xs uppercase hidden lg:flex">
      <li>
        <a
          class="flex items-center transition"
          href="https://www.facebook.com/medialineshop/"
          target="_blank"
        >
          <img
            class="mr-1"
            src="~/assets/images/ill/icon_facebook.svg"
            alt="Facebook"
          />
          <div class="hidden sm:block">Facebook</div>
        </a>
      </li>
      <li>
        <a
          class="flex items-center transition"
          href="https://www.instagram.com/media_line.ch/"
          target="_blank"
        >
          <img
            class="mr-1"
            src="~/assets/images/ill/icon_instagram.svg"
            alt="Instagram"
          />
          <div class="hidden sm:block">Instagram</div>
        </a>
      </li>
    </ul>
    <div class="justify-end flex items-center">
      <ul
        class="contact list-reset hidden lg:flex justify-end text-2xs uppercase"
      >
        <li v-for="(location, index) in locations" :key="index">
          <a class="transition" :href="'tel:' + location.phone">
            {{ location.title }} {{ location.phone }}
          </a>
        </li>
        <li>
          <a class="transition" href="mailto:mail@media-line.ch">
            mail@media-line.ch
          </a>
        </li>
      </ul>
      <ul v-if="!open" class="text-2xs list-reset h-full lg:ml-2 lg:block">
        <li class="book-appointment">
          <BookAppointmentButton />
        </li>
      </ul>
    </div>

    <!-- ONLY FOR MOBILE MENU -->
    <ContactButton v-model="open" @input="$emit('input', $event)" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContactButton from '~/components/menu/ContactButton'
import BookAppointmentButton from '~/components/menu/BookAppointmentButton'

export default {
  components: {
    ContactButton,
    BookAppointmentButton,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapState('settings', ['locations']),
  },
}
</script>
<style lang="scss" scoped>
li {
  @apply my-2 px-2;
}

.book-appointment {
  @apply m-0;
  @apply p-0;
  @apply h-full;
}

.contact > li:last-of-type {
  border: solid #3c5174;
  border-width: 0 0 0 1px;
  padding-right: 0;
}

a {
  @apply font-semibold text-grey-darker;

  &:hover {
    @apply text-red;
  }
}
</style>
