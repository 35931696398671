<template>
  <!-- ONLY FOR MOBILE -->
  <div class="quickcontact pt-4 px-3">
    <BookAppointmentButton class="w-full p-4 h-auto" :force-long-text="true" />
    <div
      class="my-3 bg-grey-lighter rounded-sm font-semibold text-lg uppercase"
    >
      <a
        class="font-semibold block p-5 transition"
        href="mailto:mail@media-line.ch"
      >
        mail@media-line.ch
      </a>
    </div>
    <Shops class="wow fadeIn" />
    <ul
      class="list-reset flex font-semibold text-2xs uppercase bg-grey-lighter py-4"
    >
      <li class="w-1/2 flex justify-end mr-2">
        <a
          class="flex items-center transition text-grey-darker hover:text-red"
          href="https://www.facebook.com/medialineshop/"
          target="_blank"
        >
          <img
            class="mr-1"
            src="~/assets/images/ill/icon_facebook.svg"
            alt="Facebook"
          />
          Facebook
        </a>
      </li>
      <li class="w-1/2 ml-2">
        <a
          class="flex items-center transition text-grey-darker hover:text-red"
          href="https://www.instagram.com/media_line.ch/"
          target="_blank"
        >
          <img
            class="mr-1"
            src="~/assets/images/ill/icon_instagram.svg"
            alt="Instagram"
          />
          Instagram
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Shops from '~/components/cardtypes/Shops'
import BookAppointmentButton from '~/components/menu/BookAppointmentButton'

export default {
  components: {
    Shops,
    BookAppointmentButton,
  },
}
</script>

<style lang="scss" scoped>
.quickcontact {
  background: url('../../assets/images/ill/contact.svg') bottom center no-repeat;
  background-size: contain;
  height: calc(100vh - 86px);
  overflow: auto;
}
</style>
