<template>
  <div class="block lg:hidden">
    <button
      class="flex items-center py-4 focus:outline-none"
      @click="$emit('input', !value)"
    >
      <!-- SEARCH ICON -->
      <svg
        v-show="!value"
        class="mr-5 transition"
        width="24"
        height="24"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.632898 12.8601905l-3.258673-3.2586133c-.017318-.0173184-.037633-.0294232-.05573-.0455431.641199-.9726462 1.015432-2.1369331 1.015432-3.3890105C12.333927 2.7610542 9.572873 0 6.166964 0 2.761054 0 0 2.7610542 0 6.1669636c0 3.4058495 2.760994 6.1669637 6.166904 6.1669637 1.252137 0 2.416364-.374233 3.38901-1.0154328.01612.0180375.028165.0383522.045483.0556705l3.258734 3.2587331c.489529.4894692 1.283178.4894692 1.772767 0 .489469-.489529.489469-1.2831785 0-1.7727076zm-8.465934-2.6641552c-2.225263 0-4.029132-1.8038687-4.029132-4.0290717 0-2.2252628 1.803929-4.0291316 4.029132-4.0291316 2.225143 0 4.029071 1.8039287 4.029071 4.0291316 0 2.225203-1.803928 4.0290717-4.029071 4.0290717z"
        />
      </svg>

      <!-- BURGER ICON -->
      <svg
        v-show="!value"
        key="burger"
        class="transition"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 0h28c.5522847 0 1 .44771525 1 1v2c0 .55228475-.4477153 1-1 1H1c-.55228475 0-1-.44771525-1-1V1c0-.55228475.44771525-1 1-1zm0 9h28c.5522847 0 1 .44771525 1 1v2c0 .5522847-.4477153 1-1 1H1c-.55228475 0-1-.4477153-1-1v-2c0-.55228475.44771525-1 1-1zm0 9h28c.5522847 0 1 .4477153 1 1v2c0 .5522847-.4477153 1-1 1H1c-.55228475 0-1-.4477153-1-1v-2c0-.5522847.44771525-1 1-1z"
        />
      </svg>

      <!-- CLOSE ICON -->
      <svg
        v-show="value"
        key="cross"
        class="transition"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 9.1715729L20.485281.6862915c.390525-.3905243 1.02369-.3905243 1.414214 0l1.414213 1.4142136c.390525.3905243.390525 1.0236892 0 1.4142135L14.828427 12l8.485281 8.4852814c.390525.3905243.390525 1.0236892 0 1.4142135l-1.414213 1.4142136c-.390524.3905243-1.023689.3905243-1.414214 0L12 14.8284271l-8.485281 8.4852814c-.390525.3905243-1.02369.3905243-1.414214 0L.686292 21.8994949c-.390525-.3905243-.390525-1.0236892 0-1.4142135L9.171573 12 .686292 3.5147186c-.390525-.3905243-.390525-1.0236892 0-1.4142135L2.100505.6862915c.390524-.3905243 1.023689-.3905243 1.414214 0L12 9.1715729z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BurgerButton',

  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
svg {
  fill: #3c5174;
}

button:hover svg {
  fill: #f5000d;
}
</style>
