<template>
  <!-- CART -->
  <div
    v-if="cart"
    class="flex flex-wrap md:items-center w-full font-semibold text-sm text-blue-darker traking-wide justify-between py-4"
  >
    <!-- PRODUCT -->
    <nuxt-link
      tag="div"
      class="relative flex justify-end md:justify-start items-start md:items-center flex-col md:flex-row hover:cursor-pointer pr-5 h-30 md:h-16 w-half md:w-1/2"
      :to="productUrl"
    >
      <div
        class="relative overflow-hidden bg-grey-light h-16 w-24 mr-3 rounded-sm flex-no-shrink"
      >
        <img
          class="special-img-shop-xs"
          :src="product.image"
          alt="product image"
        />
      </div>
      <div class="w-full overflow-hidden">
        <h3
          class="mt-2 md:mt-0 mb-0 font-semibold tracking-wide uppercase text-sm md:mb-1"
          :title="product.name"
        >
          {{ product.name }}
        </h3>
        <div
          class="text-3xs leading-loose whitespace-no-wrap text-blue-dark mb-1 truncate"
        >
          <span v-if="product.variant">{{ product.variant.title }}</span>
          <span v-if="product.version">- {{ product.version.title }}</span>
        </div>
      </div>
    </nuxt-link>

    <!-- QUANTITY AND PRICE -->
    <div
      class="flex items-end md:items-center flex-col mb-8 md:mb-0 md:flex-row w-half md:w-1/2 justify-end md:justify-between"
    >
      <QuantityCounter :value="product.quantity" @input="setQuantity" />
      <div class="block md:flex md:items-center mt-3">
        <div class="font-semibold text-grey-darker uppercase text-base">
          {{ product.priceTotal | currency }}
        </div>

        <!-- DELETE DESKTOP -->
        <button
          class="hidden md:block w-3 ml-3"
          @click.stop="removeItem(index)"
        >
          <img
            class="w-3"
            src="~assets/images/ill/cross-fat.svg"
            alt="cross icon"
          />
        </button>
      </div>
    </div>

    <!-- PRODUCT SUPPLEMENTS -->
    <template v-if="product.hasSupplements">
      <div
        class="relative flex items-center hover:cursor-pointer sm:pr-5 sm:w-half md:w-1/2"
      >
        <div
          class="relative hidden md:block overflow-hidden w-24 mr-3 rounded-sm flex-no-shrink"
        />
        <ClassicCheck
          class="dark mt-3"
          :value="!!product.servicePackagePriceTotal"
          @input="setServicePackageOption($event)"
        >
          <span class="text-sm text-grey-darker normal-case"
            >Zusatzleistungen</span
          >
        </ClassicCheck>
        <button class="ml-3 pt-1" @click.stop="showProductSupplements()">
          <img class="h-4" src="~assets/images/ill/information.svg" />
        </button>
      </div>

      <div
        v-if="product.hasSupplements"
        class="flex items-center sm:w-half md:w-1/2 justify-end md:pr-6"
      >
        <span
          v-if="product.servicePackagePriceTotal"
          class="font-semibold text-grey-darker uppercase text-base"
          >{{ product.servicePackagePriceTotal | currency }}</span
        >
        <span v-else class="font-semibold text-grey-darker uppercase text-base"
          >-</span
        >
      </div>
    </template>

    <div class="w-full">
      <!-- DELETE MOBILE -->
      <button
        class="flex items-center justify-end w-full mt-3 md:hidden"
        @click.stop="removeItem(index)"
      >
        <img
          class="w-3 mr-2"
          src="~assets/images/ill/cross-fat.svg"
          alt="cross icon"
        /><span class="font-semibold text-xs text-grey-darker">entfernen</span>
      </button>
    </div>
  </div>

  <!-- RESUME -->
  <div
    v-else
    class="flex items-end md:items-center w-full font-semibold text-sm text-blue-darker traking-wide bg-grey rounded-sm p-2 my-2"
  >
    <!-- PRODUCT -->
    <nuxt-link
      tag="div"
      class="relative flex justify-end md:justify-start items-start md:items-center flex-col md:flex-row hover:cursor-pointer pr-5 h-16 w-3/4 w-full"
      :to="productUrl"
    >
      <div
        class="relative overflow-hidden bg-grey-light h-16 w-24 mr-3 rounded-sm flex-no-shrink"
      >
        <img
          class="special-img-shop-xs"
          :src="product.image"
          alt="product image"
        />
      </div>
      <div class="w-full overflow-hidden">
        <h3
          class="mt-2 md:mt-0 mb-0 font-semibold tracking-wide uppercase text-xs truncate"
          :title="product.name"
        >
          <span
            v-if="product.quantity > 1"
            class="text-blue-dark opacity-75 normal-case"
            >{{ product.quantity }}x</span
          >
          {{ product.name }}
        </h3>
        <div
          class="text-3xs leading-loose whitespace-no-wrap text-blue-dark mb-1 truncate"
        >
          <span v-if="product.variant">{{ product.variant.title }}</span>
          <span v-if="product.version">- {{ product.version.title }}</span>
        </div>
        <div class="font-semibold text-grey-darker text-xs">
          <span class="uppercase">{{ product.priceSumTotal | currency }}</span>
          <span v-if="product.servicePackagePriceTotal" class="text-3xs italic"
            >inkl. Zusatzleistungen</span
          >
        </div>
      </div>

      <button
        class="hidden md:block w-3 ml-3 absolute pin-t pin-r"
        @click.stop="removeItem(index)"
      >
        <img
          class="w-3"
          src="~assets/images/ill/cross-fat.svg"
          alt="cross icon"
        />
      </button>
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import QuantityCounter from '~/components/QuantityCounter'
import ClassicCheck from '~/components/checkboxes/ClassicCheck'

export default {
  components: {
    QuantityCounter,
    ClassicCheck,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
    cart: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('cart', ['itemBySku']),

    productUrl() {
      let url = this.product.url
      const query = []

      if (this.product.variant) {
        query.push(`c=${this.product.variant.slug}`)
      }

      if (this.product.version) {
        query.push(`v=${this.product.version.slug}`)
      }

      if (query.length) {
        url = url.concat(`?${query.join('&')}`)
      }

      return url
    },

    index() {
      return this.itemBySku(this.product.skuId)
    },
  },

  methods: {
    ...mapMutations('cart', [
      'updateQuantity',
      'removeItem',
      'setServicePackageSelected',
      'showProductSupplementsAtIndex',
    ]),

    setQuantity(quantity) {
      this.updateQuantity({ quantity, index: this.index })
    },

    showProductSupplements() {
      this.showProductSupplementsAtIndex(this.index)
    },

    setServicePackageOption(selected) {
      this.setServicePackageSelected({ selected, index: this.index })
    },
  },
}
</script>
