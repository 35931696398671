<template>
  <div class="my-6">
    <client-only>
      <div style="position: relative; padding-top: 56.25%">
        <iframe
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          type="text/html"
          :src="
            link +
            '?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0'
          "
        />
      </div>
    </client-only>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
}
</script>
