<template>
  <div>
    <nuxt-link
      to="/"
      class="flex items-end hover:cursor-pointer"
      title="Media Line Home"
    >
      <img
        class="w-27 lg:w-auto lg:mr-2"
        src="~assets/images/ill/medialine-logo.svg"
        alt="media-line logo"
      />
      <!-- <Logo class="w-auto mr-2" /> -->
      <span class="hidden font-semibold text-3xs text-grey-dark uppercase">
        sound vision multimedia
      </span>
    </nuxt-link>
  </div>
</template>

<script>
// import Logo from '~/components/Logo'

export default {
  components: {
    // Logo,
  },
}
</script>

<style lang="scss" scoped>
span {
  padding-bottom: 6px;
}

@screen xl {
  span {
    display: inline;
  }
}
</style>
