export const state = () => ({})

export const getters = {}

export const actions = {
  async nuxtServerInit({ dispatch }, { app }) {
    await dispatch('settings/load')
  },
}

export const mutations = {}
