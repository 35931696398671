<template>
  <div>
    <number-input
      :value="value"
      :min="1"
      inline
      controls
      class="quantity-counter"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.quantity-counter.number-input {
  display: flex !important;
  align-items: center !important;
  height: 2.25rem;

  .number-input__button {
    @apply relative w-7 h-7 bg-grey-darker border-none rounded-sm;

    transition: all 0.5s ease;

    &:before,
    &:after {
      background-color: white;
    }

    &:before {
      height: 0.15rem;
    }

    &:after {
      width: 0.135rem;
    }
  }

  .number-input__button:hover {
    @apply bg-black;

    &:before,
    &:after {
      background-color: white;
    }
  }

  .number-input__input {
    @apply h-7 w-11 px-2 py-1 mx-2 rounded-sm font-semibold text-sm text-center;
    margin-top: 0.15rem;
    border: 2px solid #c8cfdb;

    &:focus {
      border: 2px solid #3c5174;
    }
  }
}
</style>
