import debounce from 'lodash/debounce'
import { roundFloorCHF } from '../libs/chf-currency'

const skuId = (product, version = null, variant = null) => {
  // skuId consists of "main-variation-version", "main-variation", "main--version" or "main"

  let skuId = `${product.id}`

  if (variant) {
    skuId = skuId.concat(`-${variant.id}`)
  }

  if (!variant && version) {
    skuId = skuId.concat(`-`)
  }

  if (version) {
    skuId = skuId.concat(`-${version.id}`)
  }

  return skuId
}

const image = (product, variant = null) => {
  if (variant && variant.images && variant.images.length > 0) {
    return variant.images[0]
  }
  return product.image
}

const price = (product, variant = null, quantity = 0) => {
  let price = product.price
  if (variant && variant.price > 0) {
    price = variant.price
  }

  const discountPercentages = discount(product, quantity)
  if (discountPercentages > 0) {
    price = (price * (100 - discountPercentages)) / 100
  }
  return price
}
const servicePackagePrice = (product, selected) => {
  return selected ? product.service_package_price : 0
}

const discount = (product, quantity = 0) => {
  if (product.graduated_prices && product.graduated_prices.length) {
    const graduatedPrice = product.graduated_prices.find(
      (graduatedPrice) =>
        graduatedPrice.from <= quantity && graduatedPrice.to >= quantity
    )
    if (graduatedPrice) {
      return graduatedPrice.discount
    }
  }
  return 0
}

const mapCartItem = (item) => {
  const itemPrice = price(item.product, item.variant, item.quantity)
  const itemServicePackagePrice = servicePackagePrice(
    item.product,
    item.servicePackageSelected
  )

  const priceTotal = roundFloorCHF(itemPrice * item.quantity)
  const servicePackagePriceTotal = roundFloorCHF(
    itemServicePackagePrice * item.quantity
  )

  return {
    id: item.product.id,
    skuId: item.skuId,
    name: item.product.title,
    image: image(item.product, item.variant),
    version: item.version,
    variant: item.variant,
    url: item.product.url,
    price: itemPrice,
    quantity: item.quantity,
    priceTotal,
    quantityInterval: item.product.quantity_interval,
    appliedDiscount: discount(item.product, item.quantity),
    hasSupplements: !!item.product.service_package_price,
    servicePackagePriceTotal,
    priceSumTotal: priceTotal + servicePackagePriceTotal,
  }
}

export const state = () => ({
  shippingPrice: {},
  items: [],
  activeVoucher: {},
  voucherCode: null,
  showProductSupplements: null,
})

export const getters = {
  hasItems: (state) => {
    return state.items.length > 0
  },

  total: (state) => {
    let total = 0

    state.items.forEach((item) => {
      const itemPrice =
        price(item.product, item.variant, item.quantity) +
        servicePackagePrice(item.product, item.servicePackageSelected)
      const totalPrice = itemPrice * item.quantity
      total += (Math.floor((totalPrice * 100) / 5) * 5) / 100
    })

    if (state.activeVoucher.voucherPrice) {
      total += state.activeVoucher.voucherPrice
    }

    return total
  },

  totalExclVAT: (state, getters) => {
    return getters.total / 1.077
  },

  totalItems: (state) => {
    return state.items
      .map((item) => item.quantity)
      .reduce((total, amount) => total + amount, 0)
  },

  cartList: (state) => {
    return state.items.map((item) => mapCartItem(item))
  },

  checkoutItems: (state) => {
    return state.items.map((item) => ({
      origin_id: item.skuId,
      amount: item.quantity,
      service_package_selected: item.servicePackageSelected,
    }))
  },

  cartItemByProductId: (state) => (productId) => {
    const item = state.items.find((item) => item.product.id === productId)
    if (!item) return null
    return mapCartItem(item)
  },

  /**
   * check if items already exists
   * @param state
   * @returns {function(*): (number | never | *)}
   */
  itemBySku: (state) => (skuId) => {
    return state.items.findIndex((cardItem) => {
      return cardItem.skuId === skuId
    })
  },

  /**
   * @param state
   * @returns {function(*): (number | never | *)}
   */
  skuByItem:
    (state) =>
    ({ product, version = null, variant = null }) => {
      return skuId(product, version, variant)
    },

  activeVoucher: (state) => {
    return state.activeVoucher
  },

  voucherCode: (state) => {
    return state.voucherCode
  },

  isActiveVoucher: (state) => {
    return state.activeVoucher.voucherPrice || state.activeVoucher.voucherLabel
  },

  isValidVoucher: (state) => {
    return (
      state.activeVoucher.voucherPrice &&
      state.activeVoucher.voucherLabel &&
      (!state.activeVoucher.voucherErrors ||
        state.activeVoucher.voucherErrors.length === 0)
    )
  },
}

export const actions = {
  addItem(
    { commit, getters, state },
    { product, version, variant, quantity = 1 }
  ) {
    const index = getters.itemBySku(skuId(product, version, variant))

    if (index >= 0) {
      commit('increaseQuantity', { quantity, index })
    } else {
      commit('addItem', { product, version, variant, quantity })
    }

    // triger product supplements on first add
    if (index === -1) {
      commit('showProductSupplementsAtIndex', state.items.length - 1)
    }
  },

  removeItem({ commit }, index) {
    commit('removeItem', index)
  },

  calculateCartPrice: debounce(
    async function ({ commit, getters }) {
      // also load voucher

      const {
        data: {
          shipping: { price: shippingPrice },
          voucher: {
            price: voucherPrice,
            label: voucherLabel,
            errors: voucherErrors,
          },
          vat_excluded: vatExcluded,
        },
      } = await this.$shop.$post('/v1/medialine/orders/checkout/calculate', {
        items: getters.checkoutItems,
        voucher_code: getters.voucherCode,
      })
      commit('setShippingPrice', {
        shippingPrice,
        vatExcluded,
      })

      commit('setActiveVoucher', {
        voucherPrice,
        vatExcluded,
        voucherLabel,
        voucherErrors,
      })
    },
    800,
    { trailing: true }
  ),

  async useVoucherCode({ commit, dispatch }, code = '') {
    code = code.toUpperCase().trim()

    if (!code) throw new Error('Bitte Gutschein Code eingeben')

    const { valid } = await this.$shop
      .$get(`/v1/vouchers/${code}`)
      .catch((e) => ({}))
    if (valid !== true) throw new Error('Ungültiger Gutschein Code')

    commit('setVoucherCode', code)

    await dispatch('calculateCartPrice')
  },

  async clearVoucher({ commit, dispatch }) {
    commit('clearVoucherCode')

    await dispatch('calculateCartPrice')
  },
}

export const mutations = {
  addItem(state, { product, version, variant, quantity = 1 }) {
    state.items.push({
      quantity,
      product,
      version,
      variant,
      skuId: skuId(product, version, variant),
      servicePackageSelected: false,
    })
  },

  removeItem(state, index) {
    state.items.splice(index, 1)
  },

  updateQuantity(state, { quantity, index }) {
    state.items[index].quantity = quantity
  },

  increaseQuantity(state, { quantity, index }) {
    state.items[index].quantity += quantity
  },

  clear(state) {
    state.items = []
    state.activeVoucher = {}
    state.voucherCode = null
  },

  cartOpen(state) {
    state.showCart = true
  },

  cartClose(state) {
    state.showCart = false
  },

  cartToggle(state) {
    state.showCart = !state.showCart
  },

  setShippingPrice(state, { shippingPrice, vatExcluded }) {
    state.shippingPrice = { shippingPrice, vatExcluded }
  },

  setActiveVoucher(
    state,
    { voucherPrice, vatExcluded, voucherLabel, voucherErrors }
  ) {
    if (voucherPrice || voucherLabel) {
      state.activeVoucher = {
        voucherPrice,
        vatExcluded,
        voucherLabel,
        voucherErrors,
      }
    } else {
      state.activeVoucher = {}
    }
  },

  setVoucherCode(state, voucherCode) {
    state.voucherCode = voucherCode
  },

  clearVoucherCode(state) {
    state.voucherCode = null
    state.activeVoucher = {}
  },

  applyVouchercode(state, voucherCode) {
    state.voucherCode = voucherCode
  },

  hideProductSupplements(state) {
    state.showProductSupplements = null
  },

  showProductSupplementsAtIndex(state, index) {
    state.showProductSupplements = state.items[index]
  },

  setServicePackageSelected(state, { selected, index }) {
    state.showProductSupplements = null
    if (index < 0) return
    state.items[index].servicePackageSelected = selected
  },
}
