/* eslint-disable */
export default () => {
  if (process.env.NODE_ENV !== 'production') return

  window.smartlook||(function(d) {
    var o=window.smartlook=function(){o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
  var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
  c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
  })(document);
  smartlook('init', '10ced34a002e3bd5b4470d7a5ed65b7302ef707b');
}
