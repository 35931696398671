<template>
  <div class="flex-grow items-center">
    <transition name="slide-down" mode="out-in">
      <div
        v-show="open || !collapsible"
        :class="[open ? 'h-screen' : 'h-0']"
        class="lg:h-auto lg:flex flex-grow items-center mt-5 lg:mt-0 text-base lg:text-xs uppercase"
      >
        <!-- SEARCH BAR -->
        <Search class="flex-1 my-2 lg:my-0 mx-0 lg:mx-3" />

        <!-- WEBSITE NAVIGATION -->
        <ul class="list-reset block lg:flex items-center">
          <template v-for="page in pages">
            <li v-if="!page.show_subnavigation" :key="page.id">
              <nuxt-link
                :to="page.url"
                class="nuxt-link inline-block w-full transition"
              >
                {{ page.title }}
              </nuxt-link>
            </li>

            <li
              v-if="page.show_subnavigation"
              :key="page.id"
              class="transition mt-5 lg:mt-0 hover:cursor-pointer hover:text-red ml-1 lg:ml-0"
              @mouseenter="$emit('mouseenter')"
              @mouseleave="$emit('mouseleave')"
            >
              <nuxt-link
                :to="page.children[0].url"
                class="nuxt-link hidden lg:flex items-center justify-between"
              >
                {{ page.title }}
              </nuxt-link>

              <!-- ONLY FOR MOBILE -->
              <div
                class="flex lg:hidden items-center justify-between -ml-1 font-semibold"
                :class="[showCollapse ? 'text-grey-darker' : 'text-red']"
                @click="showCollapse = !showCollapse"
              >
                {{ page.title }}
                <Arrow
                  class="arrow black transition"
                  :class="[showCollapse ? 'down' : 'up']"
                />
              </div>
              <transition tag="div" name="slide-down" mode="in-out">
                <MenuSub
                  v-show="!showCollapse"
                  :pages="page.children"
                  class="-ml-1"
                />
              </transition>
            </li>
          </template>
        </ul>
      </div>
    </transition>
    <CartImage class="hidden lg:flex" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Search from '~/components/menu/Search'
import MenuSub from '~/components/menu/MenuSub'
import CartImage from '~/components/checkout/CartImage'
import Arrow from '~/components/Arrow'

export default {
  components: {
    Search,
    MenuSub,
    CartImage,
    Arrow,
  },

  props: {
    collapsible: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showCollapse: true,
    }
  },

  computed: {
    ...mapState('settings', ['pages', 'isOpen']),
  },
}
</script>

<style lang="scss" scoped>
li {
  @apply py-6 px-0 tracking-wide;

  &:hover {
    .nuxt-link {
      @apply text-red;
    }
    .arrow {
      @apply border-red;
    }
  }

  .nuxt-link {
    @apply font-semibold text-grey-darker;
  }
}

@screen lg {
  li {
    @apply inline-block py-8;

    .nuxt-link {
      @apply py-8 px-1 text-grey-darker text-xs;
    }

    .nuxt-link:first-of-type {
      @apply py-0;
    }

    &:hover {
      .nuxt-link {
        @apply text-red;
      }
    }
  }

  .arrow {
    @apply hidden;
    margin-bottom: 4px;
  }
}
</style>
