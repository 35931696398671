<template>
  <div class="my-5">
    <content-carousel :images="images" />
  </div>
</template>

<script>
import ContentCarousel from '~/components/carousel/ContentCarousel'

export default {
  components: {
    ContentCarousel,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
}
</script>
