<template>
  <!-- A BIGGER CARD FOR ALL THE SHOP ADDRESSES - USES SHOPSCARD -->
  <div>
    <ShopsCard
      v-for="location in locations"
      :key="location.id"
      :location="location"
      :highlight="highlight"
    >
      <template slot="title"> Standort {{ location.title }} </template>
      <template slot="street">
        {{ location.street }}
      </template>
      <template slot="town"> {{ location.zip }} {{ location.place }} </template>
      <template slot="phone">
        <a
          class="flex justify-center lg:justify-end items-center py-3 lg:py-1 px-4 lg:px-0 mb-2 lg:mb-0 bg-grey lg:bg-transparent font-semibold text-xs text-red cursor-pointer hover:text-black transition"
          :href="'tel:' + location.phone"
        >
          {{ location.phone }}
        </a>
      </template>
    </ShopsCard>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ShopsCard from '~/components/cardtypes/ShopsCard'

export default {
  components: {
    ShopsCard,
  },

  props: {
    highlight: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('settings', ['locations']),
  },
}
</script>
