<template>
  <div class="relative overflow-hidden flex flex-wrap lg:flex-no-wrap">
    <div
      class="relative ml-0 lg:ml-map w-full lg:max-w-third z-30 px-4 md:px-0"
    >
      <h3 id="standorte" class="pt-8 px-4 font-semibold text-xl uppercase">
        Unsere Standorte
      </h3>

      <!-- SHOP ADDRESSES -->

      <Shops highlight class="mb-8 md:mb-0 wow fadeIn" />
    </div>

    <!-- MAPS -->

    <div class="w-full">
      <div class="hidden lg:block absolute pin z-20 w-3/5 h-screen gradient" />
      <GoogleMap
        class="w-full lg:w-3/5 h-80 lg:h-full relative lg:absolute pin-t pin-r z-10"
      />
    </div>
  </div>
</template>

<script>
import GoogleMap from '~/components/GoogleMap'
import Shops from '~/components/cardtypes/Shops'

export default {
  components: {
    GoogleMap,
    Shops,
  },
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 43%,
    rgba(255, 255, 255, 1) 68%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
