<template>
  <div href="/">
    <div
      class="relative w-full overflow-hidden"
      :class="[
        {
          'flex flex-col items-center h-screen lg:h-tqscreen bg-black nav-space':
            home,
        },
        { 'h-120': !home },
      ]"
    >
      <!-- SMALL TEXT BOX -->

      <div class="relative w-full h-full flex justify-center items-center">
        <slot />
      </div>
      <div
        v-if="home"
        class="lg:absolute pin-b pin-l max-w-full md:max-w-sm m-4 lg:m-20 p-10 bg-white rounded-sm z-100"
      >
        <Paragraph>
          <h2><slot name="title" /></h2>
          <p><slot name="paragraph" /></p>
        </Paragraph>
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from '~/components/Paragraph'

export default {
  components: {
    Paragraph,
  },
  props: {
    home: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
button:hover a {
  @apply text-black;
}
</style>
