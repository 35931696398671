<template>
  <div class="search-dropdown absolute z-50 w-full bg-grey">
    <template v-if="loading">
      <SearchItem class="py-3">
        <template slot="results">
          <div class="py-1 px-3 text-xs normal-case">Lädt..</div>
        </template>
      </SearchItem>
    </template>
    <template v-if="!loading">
      <SearchItem v-if="!(mappedResult.length > 0)" class="py-3">
        Nichts gefunden
        <template slot="results">
          <div class="py-1 px-3 text-xs normal-case">
            Leider konnten wir zu diesem Suchbegriff nichts finden.
          </div>
        </template>
      </SearchItem>
      <SearchItem
        v-for="(category, index) in mappedResult"
        :key="index"
        class="py-3"
      >
        {{ category[0].type }}
        <template slot="results">
          <nuxt-link
            v-for="(entry, secIndex) in category"
            :key="entry.id"
            class="block result py-1 px-3 text-xs normal-case transition"
            :class="{ selected: isSelectedEntry(secIndex + index) }"
            :to="entry.url"
          >
            <div class="flex flex-wrap justify-between">
              <div class="w-1/2 leading-tight">
                {{ entry.title }}
              </div>
              <div
                v-if="entry.price"
                class="w-1/2 font-semibold text-grey-darker leading-tight text-right transition"
              >
                {{ entry.price | currency }}
              </div>
            </div>
          </nuxt-link>
        </template>
      </SearchItem>
    </template>
  </div>
</template>
<script>
import groupBy from 'lodash/groupBy'
import SearchItem from '~/components/dropdown/SearchItem'

export default {
  components: {
    SearchItem,
  },
  props: {
    results: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedElement: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    mappedResult() {
      if (!this.results) {
        return []
      }

      const results = groupBy(this.results, 'type')
      // return

      return Object.values(results)
    },
  },
  methods: {
    isSelectedEntry(index) {
      return index + 1 === this.selectedElement
    },
  },
}
</script>

<style lang="scss" scoped>
.result:hover,
.result.selected {
  @apply bg-grey-darker text-white cursor-pointer;

  div {
    @apply text-white;
  }
}
</style>
